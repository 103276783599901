<script setup lang="ts">
import type { SliceInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    direction: VoixSelectFieldInterface
    media: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Horizontal Card', group: 'Kimpton', layouts: ['Kimpton'] },
  description: 'A large image flanking title, copy, and a link.',
  preview: 'SlicesKimptonHorizontalCard.jpg',
  fields: {
    direction: {
      type: 'select',
      label: 'Direction',
      options: {
        left: 'Image on Left',
        right: 'Image on Right',
      },
      default: 'left',
    },
    media: {
      type: 'media',
      label: 'Media',
      breakpoints: {
        default: {
          width: 340,
          height: 210,
        },
        md: {
          width: 1000,
          height: 570,
        },
      },
    },

    title: {
      type: 'text',
      label: 'Title',
    },

    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },

    link: {
      type: 'link',
      label: 'Link',
      enabled: true,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      media: {
        value: 'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      title: {
        value: 'This is the title',
      },
      copy: {
        value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in turpis nec felis ultricies ultricies. Nullam in turpis nec felis ultricies ultricies. Nullam in turpis nec felis ultricies ultricies.</p>',
      },
      link: {
        value: {
          text: 'The Link',
          url: '#',
        },
      },
    },
  }],
})

const triangleMask = computed(() => {
  if (props.fields.direction.value === 'left')
    return 'triangle-mask-right'
  else
    return 'triangle-mask-left'
})
</script>

<template>
  <div class="py-12">
    <div class="container mx-auto">
      <div class="lg:grid lg:grid-cols-12 lg:gap-16">
        <div v-if="fields.direction.value === 'left'" class="relative lg:col-span-8">
          <div :class="triangleMask">
            <VoixMedia :field="fields.media" class="w-full h-full object-cover" />
          </div>
        </div>

        <div class="lg:col-span-4 flex flex-col justify-center">
          <div class="py-8">
            <h3 class="text-2xl">
              {{ fields.title.value }}
            </h3>
            <VoixWysiwyg :field="fields.copy" class="pt-8" />

            <div v-if="fields.link.enabled" class="mt-8">
              <VoixLink :field="fields.link" class="block text-center bg-kimpton-action text-white px-8 py-3" />
            </div>
          </div>
        </div>

        <div v-if="fields.direction.value === 'right'" class="relative lg:col-span-8">
          <div :class="triangleMask">
            <VoixMedia :field="fields.media" class="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.triangle-mask-right {
  clip-path: polygon(50% 0%, 100% 0, 100% 6%, 95% 14%, 100% 22%, 100% 100%, 52% 100%, 0 100%, 0 51%, 0 0);
}
.triangle-mask-left {
  clip-path: polygon(50% 0%, 0 0, 0 6%, 5% 14%, 0 22%, 0 100%, 48% 100%, 100% 100%, 100% 51%, 100% 0);
}
</style>
